/* CSS reset using normalize */
@import url('https://cdn.jsdelivr.net/npm/normalize.css@8.0.1/normalize.css');

@font-face {
  font-family: 'CircularXX';
  src: url('/fonts/CircularXXWeb-Regular.woff2') format('woff2'),
    url('/fonts/CircularXXWeb-Regular.woff') format('woff');

  font-display: swap;
}

@font-face {
  font-family: 'CircularXX';
  font-weight: bold;
  src: url('/fonts/CircularXXWeb-Bold.woff2') format('woff2'),
    url('/fonts/CircularXXWeb-Bold.woff') format('woff');

  font-display: swap;
}

html,
body {
  /* TODO: allow overscroll on mobile devices */
  overscroll-behavior: none;
  font-family: 'CircularXX', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  font-size: 100%;
  background-color: #edeef0;

  width: 100%;
  height: 100%;
}

/* More consistend box model */

*,
*::before,
*::after {
  box-sizing: border-box;
}
